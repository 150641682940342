import React, {useState, useEffect} from 'react';
const UrlContext = React.createContext();

export const UrlContextProvider = (props) => {
    //const [baseUrl, setBaseUrl] = useState('http://localhost:8081');
    const [baseUrl, setBaseUrl] = useState('https://merke.be');
    const [executed, setExecuted] = useState(false);
   
    useEffect(() => {
        const local = false;
        const dev = true;
        
        if (local) {
            setBaseUrl('http://localhost:8081');
        } else {
            if (dev) {
                setBaseUrl('https://dev.merke.be');
            }
        }
        setExecuted(() => true);
    },[]);


    return (
            <UrlContext.Provider value={{baseUrl: baseUrl, setBaseUrl: setBaseUrl, ex: executed}}>
                {props.children}
            </UrlContext.Provider>
            );
};

export default UrlContext;
