import React from 'react'
import { useTranslation } from 'react-i18next';

const DayTime = (props) => {
    
    const venue = props.venue;
    
    const {t} = useTranslation();

    const renderTime = () => {
        if (venue.startHour || venue.endHour) {
            const formattedStartHour = venue.startHour.substring(0, venue.startHour.length - 3);
            const formattedEndHour = venue.endHour.substring(0, venue.startHour.length - 3);
            return (<span className="hours" style={{float: 'right'}}>{formattedStartHour} - {formattedEndHour}</span>);
        }
    };

    const renderDay = () => {
        if (venue.venueType === 'MARKET') {
            var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

            let dayIndex = null;
            days.forEach((day, index) => {
                if (venue[day]) {
                    dayIndex = index;
                }
            });
            return <span className="day">{t('days.' + days[dayIndex])}</span>;
        }
    };
    
        return (
                <div className="day-time">
                    {renderDay(venue)}
                    {renderTime(venue)}
                </div>
                );
};

export default DayTime