import React from "react";
import {useParams, Link} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";

import './ArticleDetail.scss';

function ArticleDetail(props) {
    const {articleId} = useParams();
    const thisArticle = props.articles.find(article => article.id === articleId);
    console.log(thisArticle);
    if (thisArticle) {
        return (
                <div>
                    <Helmet>
                        <title>Fori: {thisArticle.title}</title>
                    </Helmet>
                    <div className="header-bar sticky">
                        <div className="icon">
                            <Link to="/info"><FontAwesomeIcon icon={faArrowLeft} className="2x"/></Link>
                        </div>
                        <span className="header-bar-text">{thisArticle.title}</span>
                        <span></span>
                    </div>
                    <article>
                        <img className="article-image" alt="article" src={`../${thisArticle.imgUrl}`} />
                        <div className="article-content" dangerouslySetInnerHTML={{__html: thisArticle.content}}></div>
                    </article>
                </div>
                );
    } else {
        return (
                <div>
                    <Helmet>
                        <title>Fori: Article Loading</title>
                    </Helmet>
                    <div className="header-bar">
                        <div className="icon">
                            <Link to="/info"><FontAwesomeIcon icon={faArrowLeft} className="2x"/></Link>
                        </div>
                        <span className="header-bar-text">Loading</span>
                        <span></span>
                    </div>
                    <div>Loading... </div>
                </div>
                );
    }

}

export default ArticleDetail