import React, { useState, useEffect } from "react"
import {Link} from "react-router-dom"
import VenueList from '../components/venuelist/VenueList';
import SearchBox from '../components/searchbox/SearchBox';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useHistory, useLocation} from "react-router-dom"

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


const Search = (props) => {

    const {t} = useTranslation();
    const history = useHistory();
    const [searchValue, setSearchValue] = useState('loadsearch');
    const [firstRender, setFirstRender] = useState(false);

    const search = useLocation().search;

    useEffect(() => {
        if (searchValue !== 'loadsearch') {
            history.push({
                pathname: window.location.pathname,
                search: '?search=' + searchValue
            });
        } else {
            history.push({
                pathname: window.location.pathname
            });
        }
    }, [searchValue]);

    useEffect(() => {
        const urlSearchVal = new URLSearchParams(search).get("search");
        if (!firstRender) {
            if (urlSearchVal) {
                setSearchValue(urlSearchVal);

                history.push({
                    pathname: window.location.pathname,
                    search: '?search=' + searchValue
                });
            }

            setFirstRender(true);
        }
    }, [firstRender]);

    return (
            <div>
                <Helmet>
                    <title>Fori: {t('planMyVisit')}</title>
                </Helmet>
                <div className="sticky">
                    <div className="header-bar">
                        <div className="icon">
                            <Link to="/"><FontAwesomeIcon icon={faArrowLeft} className="2x"/></Link>
                        </div>
                        <span className="header-bar-text">{t('planMyVisit')}</span>
                        <div style={{width: '0.875em'}}/>
                    </div>
                    <div className="searchbox-container" >
                        <SearchBox onchangefn={setSearchValue} searchValue={searchValue}/>
                    </div>
                </div>
                <VenueList type="search" searchValue={searchValue} noresulttext={t('emptyTextSearch')} venues={props.venues} setVenues={props.setVenues} pathData={props.pathData}/>
            </div>
            );
};


export default Search