import React, {useState, useEffect, useContext} from "react";
import uuid from 'react-uuid';
import { useLocation, useHistory, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faExclamationTriangle, faSpinner, faDharmachakra, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import './VenueList.scss';

import ExtraInfoItem from './ExtraInfoItem';
import RemarkItem from './RemarkItem';
import Location from './Location';
import DayTime from '../../components/daytime/DayTime';

import UrlContext from "../../urlContext"


        const Remarks = (props) => {
    const {venue, relocated, cancelled} = props;
    return (
            <div className="remarks-container">
                { venue.dataVerifiedByAdmin === false && <RemarkItem type="verified" data={venue.dataVerifiedByAdmin}/>}
                { cancelled && <RemarkItem type="cancellation" />}
                { relocated && <RemarkItem type="relocation" data={venue.cancellations}/>}
            </div>
            );
};

const VenueItem = (props) => {
    const {t} = useTranslation();
    const {venue, type} = props;
    const {context} = venue;


    const [isCancelled, setIsCancelled] = useState(false);
    const [cancellationReason, setCancellationReason] = useState(null);
    const [isRelocated, setIsRelocated] = useState(false);
    const [relocationReason, setRelocationReason] = useState(null);
    const [isToggled] = useState(false);
    //const [isToggled, setIsToggled] = useState(false);
    //const toggle = () => setIsToggled(prevState => !prevState);

    const setLastActivePath = props.pathData[1];
    const history = useHistory();
    const location = useLocation();

    const setActiveCircumstances = () => {
        if (venue.firstUpcomingEvent) {
            const eventStartDate = Date.parse(venue.firstUpcomingEvent.start);

            const setActiveCircumstance = (arr, stateFn, stateReasonFn) => {
                if (arr && arr.length > 0) {
                    for (let i = 0; i < arr.length; i++) {
                        if (Date.parse(arr[i].startDate) <= eventStartDate && eventStartDate <= Date.parse(arr[i].endDate)) {
                            stateFn(() => {
                                return true;
                            });

                            stateReasonFn(() => {
                                return arr[i].reason;
                            });
                            break;
                        }
                    }
                }
            };
            setActiveCircumstance(venue.cancellations, setIsCancelled, setCancellationReason);
            setActiveCircumstance(venue.relocations, setIsRelocated, setRelocationReason);
        }
    };

    useEffect(() => {
        setActiveCircumstances();
    });

    const renderLocation = () => {

        const locations = venue.location.split(/[;,]/);
        const originalLocations = locations.map(myLocation => <Location key={uuid()} location={myLocation} moved={isRelocated} context={venue.context}/>);

        let relocations = null;

        if (isRelocated) {
            relocations = venue.relocations.map(relocation => <Location key={uuid()} location={relocation.newLocation} moved={!isRelocated} context={venue.context}/>);
        }
        return(
                <React.Fragment>
                    {originalLocations}
                    {relocations}
                </React.Fragment>
                );
    };

    const itemClickHandler = (type, id) => {
        
        if (location.search) {
            setLastActivePath(location.pathname + location.search);
        } else {
            setLastActivePath(location.pathname);
        }
        history.push(`/${type === 'MARKET' ? 'market' : 'fair'}/${id}`);
    };

    //unfold element
    /*<span className="unfold" onClick={toggle}><FontAwesomeIcon icon={isToggled === true ? faCaretUp : faCaretDown}  size="2x"/></span>*/

    //more info
    /*<span className="more-info-text" onClick={(e) => itemClickHandler(type, venue.id)}>{t('moreInfo')}<FontAwesomeIcon  icon={faCaretRight} size="2x"/></span>*/

    return (
            <div className={`venue-list-item ${type === 'MARKET' ? 'market-item' : 'fair-item'} ${isToggled === true ? 'show' : ''}`}>
                <div className="info-container-placeholder">
                    <div className="info-container" style={{display: "flex"}}>
                        <span className="venue-icon">
                            {type === 'MARKET' ? <FontAwesomeIcon icon={faStore} /> : <FontAwesomeIcon icon={faDharmachakra} />}
                        </span> 
                        <span className="info-data">
                            <span className="context-name">{context.name}</span>
                            <span className="venue-name">{venue.name}</span>
                            <DayTime venue={venue} />
                            {venue.location && renderLocation()}
                            <div className="more-info">
            
                                <span className="more-info-text" onClick={(e) => itemClickHandler(type, venue.id)}>{t('moreInfo')}<FontAwesomeIcon  icon={faCaretRight} size="2x"/></span>
                            </div>
                        </span>
                    </div>
                    <Remarks venue={venue} cancelled={isCancelled} relocated={isRelocated}/>
                    <div className="extra-info-container">
                        {(isCancelled && cancellationReason) && <ExtraInfoItem type="cancellation" reason={cancellationReason}/>}
                        {(isRelocated && relocationReason) && <ExtraInfoItem type="relocation" reason={relocationReason}/>}
                        {!venue.offer && !venue.website && !venue.plandId && !cancellationReason && !relocationReason && t('noExtraInfo')}
                        {venue.offer && <ExtraInfoItem type="offer" offer={venue.offer}/>}
                        {venue.website && <ExtraInfoItem type="website" website={venue.website}/>}
                        {venue.planId && <ExtraInfoItem  type="plan" planId={venue.planId}/>} 
                    </div>
                </div>  
            </div>
                    );
        };

        const VenueList = (props) => {
            const {t} = useTranslation();
            const {baseUrl} = useContext(UrlContext);
            const [reqInProgress, setReqInProgress] = useState(false);
            const [noZipCodes, setNoZipCodes] = useState(true);

            const {venues, setVenues} = props;
            const {type} = props;

            const [online, setIsOnline] = useState(true);

            const checkConnection = () => {
                if (window.navigator.onLine) {
                    setIsOnline(true);
                } else {
                    setIsOnline(false);
                }
            };

            let list = [];
            if (props.type === 'MARKET' || props.type === 'FAIR') {
                list = venues.filter(venueItem => venueItem.venueType === props.type).map(venueItem => <VenueItem key={venueItem.id} type={venueItem.venueType} pathData={props.pathData} venue={venueItem}/>);
            } else {
                list = venues.map(venueItem => <VenueItem key={venueItem.id} type={venueItem.venueType} venue={venueItem} pathData={props.pathData}/>);
            }

            const request = (searchValue) => {
                checkConnection();
                if (online) {
                    var url = new URL(`${baseUrl}/venues`);

                    const options = {
                        method: 'GET',
                        headers: new Headers({'Accept': 'application/json'})
                    };

                    var paramsString = new URLSearchParams();

                    let params = [
                        {"property": "getUpcomingRelocations", "value": true},
                        {"property": "getUpcomingCancellations", "value": true},
                        {"property": "getFirstUpcomingEvent", "value": true}
                    ];

                    if (searchValue) {
                        setNoZipCodes(false);
                        params.push({"property": "foriSearch", "value": props.searchValue});
                        setReqInProgress(true);
                        paramsString.append('filter', JSON.stringify(params));
                        fetch(url + '?' + paramsString, options)
                                .then(response => response.json())
                                .then(data => {
                                    setReqInProgress(false);
                                    setVenues(() => data.data);
                                    ;
                                });
                    } else if (typeof props.zipcodes !== 'undefined' && props.zipcodes !== null && props.zipcodes.length > 0) {
                        setNoZipCodes(() => false);
                        const zipcodes = props.zipcodes.map(zipcodeObj => zipcodeObj.zipcode.toString());
                        params.push({"property": "zipcodes", "value": zipcodes});
                        paramsString.append('filter', JSON.stringify(params));
                        setReqInProgress(true);
                        fetch(url + '?' + paramsString, options)
                                .then(response => response.json())
                                .then(data => {
                                    setReqInProgress(false);
                                    setVenues(() => data.data);
                                });
                    } else if (typeof props.zipcodes !== 'undefined' && props.zipcodes.length === 0) {
                        setNoZipCodes(() => true);
                        setVenues([]);
                    }
                }
            };

            useEffect(() => {
                request(props.searchValue);
            }, [props.searchValue]);

            useEffect(() => {
                request();
            }, [props.zipcodes]);

            if (online) {
                if (list.length > 0) {
                    let cls = '';

                    if (props.type) {
                        if (props.type === 'MARKET') {
                            cls = 'market-list';
                        } else {
                            cls = 'fair-list';
                        }
                    }

                    //market-list // fair-list

                    return (
                                <div className={`venue-list ${cls}`}>
                                    {list}
                                </div>

                            );
                } else {
                    let loadItemText = t('loading');
                    switch (props.type) {
                        case 'MARKET':
                            loadItemText = t('loadingMarkets');
                            break
                        case 'FAIR':
                            loadItemText = t('loadingFairs');
                            break;
                        default:
                            break;
                    }

                    let noResultText = t('noResults');

                    if (props.noresulttext) {
                        noResultText = props.noresulttext;
                    }

                    if (reqInProgress) {
                        return <div className="msg msg-loading"><FontAwesomeIcon icon={faSpinner} className="fa-spin msg-icon"  size="2x"/><span className="msg-text">{loadItemText}</span></div>;
                    } else {
                        if (noZipCodes) {
                            return <div className="msg msg-warning"><FontAwesomeIcon icon={faExclamationTriangle} className="msg-icon"  size="2x"/><span className="msg-text"><Link to="/settings/zipcodes" dangerouslySetInnerHTML={{__html: t('noZip')}}></Link></span></div>;
                        } else {
                                    return <div className="msg msg-warning"><FontAwesomeIcon icon={faExclamationTriangle} className="msg-icon"  size="2x"/><span className="msg-text">{noResultText}</span></div>;
                                }
                            }
                        }
                    } else {
                        return (
                            <div className="offline-msg">
                                {t('offlineText')}<br/>
                                {t('offlineText2')}<br/>
                                <button onClick={checkConnection}>{t('offlineCheck')}</button>
                            </div>
                                                );
                                    }


                                };

                                export default VenueList