import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import './SearchBox.scss';

const SearchBox = (props) => {

    const {t} = useTranslation();
    const [currentValue, setCurrentValue] = useState('');
    const setSearchValue = props.onchangefn;
    const [timedOut, setTimedOut] = useState(null);


    let defaultValue = 'loadsearch';
    let defaultInputName = 'zipcodes';
    let defaultBuffer = 1000;
    let defaultMinLength = 2;



    if (typeof props.buffer === "number") {
        defaultBuffer = props.buffer;
    }


    if (typeof props.minLength === "number") {
        defaultMinLength = props.minLength;
    }

    if (props.inputName) {
        defaultInputName = props.inputName;
    }

    if (typeof props.defaultValue === 'string') {
        defaultValue = props.defaultValue;
    }

    const changeFn = (e) => {
        const val = e.target.value;

        if (timedOut) {
            clearTimeout(timedOut);
        }

        if (e.target.value.length > defaultMinLength) {
            setCurrentValue(() => {
                return val;
            });
            setTimedOut(() => {
                return setTimeout(function () {
                    setSearchValue(() => {
                        return val;
                    });
                }, defaultBuffer);
            });
        } else {
            setCurrentValue(() => {
                return val;
            });
            setSearchValue(() => {
                return defaultValue;
            });
        }
    };

    useEffect(() => {
        if (props.searchValue !== 'loadsearch') {
        setCurrentValue(props.searchValue);
        }
    }, [props.searchValue]);

    const onClearIconTap = () => {
        if (timedOut) {
            clearTimeout(timedOut);
        }
        setCurrentValue('');
        setSearchValue(() => defaultValue);
    };

    return (
            <div className="searchbox-input">
                <FontAwesomeIcon icon={faSearch} className="3x search-icon"/>
                <input type="text" placeholder={t('searchTerm')} name={defaultInputName} value={currentValue} onChange={changeFn}/>
                {currentValue !== '' && <FontAwesomeIcon icon={faTimes} onClick={onClearIconTap} className="3x times-icon"/>}
            </div>

            );
};

export default SearchBox