import React, {useEffect, useState, useContext} from "react";
import {useParams, useHistory, useLocation} from "react-router-dom";
import SearchBox from '../../components/searchbox/SearchBox';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {Helmet} from "react-helmet";

import ExtraInfoItem from '../venuelist/ExtraInfoItem';
import RemarkItem from '../venuelist/RemarkItem';
import Location from '../venuelist/Location';
import DayTime from '../daytime/DayTime';

import './VenueDetail.scss';

import UrlContext from "../../urlContext";


const StallItem = (props) => {
    const {stall} = props;
    const {t} = useTranslation();
    let categories = null;
    let stallName = null;

    if (stall.name && stall.name !== '-' && stall.name !== '') {
        stallName = stall.name;
    } else {
        if (stall.vendor && stall.vendor.companyname) {
            stallName = stall.vendor.companyname;
        } else {
            stallName = /*stall.vendor.firstname + ' ' + */stall.vendor.lastname;
        }
    }

    if (stall.categories && stall.categories.length > 0) {
        categories = stall.categories.map(obj => obj.name.toLowerCase()).join(', ');
    }

    const renderPromo = () => {
        return (
                <div className="stall-promo">
                    <div className="stall-promo-block-title">Promos</div>
                    {stall.promotions.map((promo, index) => <div className="stall-promo-promo" key={uuid()}><span className="stall-promo-title">Promo {index + 1}: {promo.title}</span><br/>{promo.text}</div>)}
                </div>
                );
    };

    const SocialItem = (props) => {

        const getValidUrl = (url) => {
            let newUrl = url.trim().replace(/\s/g, "");
            if (!/^https?:\/\//i.test(newUrl)) {
                return `http://${newUrl}`;
            }
            return newUrl;
        };

        const {type, value} = props;
        let icon;
        let url;
        switch (type) {
            case 'facebook':
                icon = faFacebook;
                url = `https://www.facebook.com/${value}`;
                break;
            case 'instagram':
                icon = faInstagram;
                url = `https://www.instagram.com/${value}`;
                break;
            case 'twitter':
                icon = faTwitter;
                url = `https://www.twitter.com/${value}`;
                break;
            default:
                icon = faGlobe;
                url = getValidUrl(value);
                break;

        }
        return (
                <a href={url} rel="noreferrer" className="stall-social-link" target="_blank"><FontAwesomeIcon icon={icon} className="1x {type}"/></a>
                );
    };

    const renderSocials = (socialObj) => {
        const socialArr = Object.keys(socialObj).map((key, index) => <SocialItem key={key} type={key} value={socialObj[key]}/>);


        return (
                <div className="stall-social-urls">
                    {socialArr}
                </div>
                );
    };

    return (
            <div className="stall-item">
                <div className="stall-info">
                    <div className="stall-name">{stallName}</div>
                    {stall.presence !== '-' && <div className="stall-presence"><span className={`${stall.presence === 'Available' ? 'present' : 'absent'}`}>{`${stall.presence === 'Available' ? t('present') : t('absent')}`}</span></div>}
                </div>
                {categories &&
                            <div className="stall-cat">
                                <span className="stall-cat-title">{stall.categories.length > 1 ? 'Categorieën:' : 'Categorie:'}</span> {categories}
                            </div>

                }
            
                {stall.promotions && stall.promotions.length > 0 && renderPromo()}
                {stall.social && renderSocials(stall.social)}
            </div>);
};

const StallList = (props) => {
    const myStalls = props.stalls.map(stall => <StallItem key={uuid()} stall={stall} />);


    return <div className="stall-list">{myStalls}</div>;
};

const VenueDetail = (props) => {
    const {baseUrl, ex} = useContext(UrlContext);
    const {venueId} = useParams();
    const [lastActivePath] = props.pathData;
    const history = useHistory();
    const location = useLocation();
    const [currentVenue, setCurrentVenue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [stalls, setStalls] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [categories, setCategories] = useState([]);
    const [catSet, setCatSet] = useState(false);
    const [selectedCategorie, setSelectedCategorie] = useState('all');
    const [windowScrollPosition, setWindowScrollPosition] = useState(0);
    const {t} = useTranslation();

    const [isCancelled, setIsCancelled] = useState(false);
    const [isRelocated, setIsRelocated] = useState(false);
    const [cancellationReason, setCancellationReason] = useState(null);
    const [relocationReason, setRelocationReason] = useState(null);

    useEffect(() => {
        //not pretty -- Better solution? (avoiding double requests)
        const request = () => {
            //check if online

            if (window.navigator.onLine) {
                var url = new URL(`${baseUrl}/venues/`);

                const options = {
                    method: 'GET',
                    headers: new Headers({'Accept': 'application/json'})
                };

                var paramsString = new URLSearchParams();


                let params = [
                    {"property": "getUpcomingRelocations", "value": true},
                    {"property": "getUpcomingCancellations", "value": true},
                    {"property": "getFirstUpcomingEvent", "value": true},
                    {"property": "id", "value": venueId}
                ];

                paramsString.append('filter', JSON.stringify(params));


                setIsLoading(true);
                fetch(url + '?' + paramsString, options)
                        .then(response => response.json())
                        .then(data => {
                            if (data.success === true) {
                                setIsLoading(false);
                                loadVenue(data.data);
                            } else {
                                setCurrentVenue(null);
                            }

                            setIsLoading(false);
                        });
            } else {
                //what to do when offline
            }

        };

        if (ex) {
            request();
        }
    }, [baseUrl, ex, venueId]);

    useEffect(() => {
        const setActiveCircumstances = () => {
            if (currentVenue.firstUpcomingEvent) {
                const eventStartDate = Date.parse(currentVenue.firstUpcomingEvent.start);

                const setActiveCircumstance = (arr, stateFn, stateReasonFn) => {
                    if (arr && arr.length > 0) {
                        for (let i = 0; i < arr.length; i++) {
                            if (Date.parse(arr[i].startDate) <= eventStartDate && eventStartDate <= Date.parse(arr[i].endDate)) {
                                stateFn(() => {
                                    return true;
                                });

                                stateReasonFn(() => {
                                    return arr[i].reason;
                                });
                                break;
                            }
                        }
                    }
                };
                setActiveCircumstance(currentVenue.cancellations, setIsCancelled, setCancellationReason);
                setActiveCircumstance(currentVenue.relocations, setIsRelocated, setRelocationReason);
            }
        };

        if (currentVenue !== null && currentVenue.stalls !== null) {
            setStalls([...currentVenue.stalls]);
            setActiveCircumstances();

            let cats = [];
            if (currentVenue.stalls && currentVenue.stalls.length > 0) {
                currentVenue.stalls.forEach(stall => {
                    if (stall.categories) {
                        stall.categories.forEach(cat => {
                            if (!cats.includes(cat.name)) {
                                cats = [...cats, cat.name];
                            } else {
                                cats = [...cats];
                            }
                            ;
                        });
                    }
                });
            }
            setCategories(cats);
        }
    }, [currentVenue]);

    useEffect(() => {
        if (currentVenue && currentVenue.stalls) {
            setStalls(currentVenue.stalls
                    .filter(stall => {
                        if (stall.name && stall.name !== null && stall.name !== '-') {
                            return stall.name.toLowerCase().includes(searchValue.toLowerCase());
                        } else {
                            if ((stall.vendor.companyname && stall.vendor.companyname !== null)) {
                                return stall.vendor.companyname.toLowerCase().includes(searchValue.toLowerCase());
                            } else {
                                if (stall.vendor.lastname && stall.vendor.lastname !== null) {
                                    return stall.vendor.lastname.toLowerCase().includes(searchValue.toLowerCase());
                                } else {
                                    return false;
                                }
                            }
                        }
                    })
                    .filter(stall => {
                        if (selectedCategorie === 'all') {
                            return true;
                        } else {
                            if (stall.categories.length > 0) {
                                let found = false;
                                stall.categories.forEach(cat => {
                                    if (cat.name === selectedCategorie) {
                                        found = true;
                                    }
                                });

                                return found;
                            } else {
                                return false;
                            }
                        }
                    }));
        }
    }, [currentVenue, selectedCategorie, searchValue]);

    const scrollWindow = (yCord) => {
        window.scrollTo(yCord);
    };

    const handleBackClick = () => {
        if (lastActivePath === location.pathname) {
            history.push('/');
        } else {
            history.push(lastActivePath);
        }
    };

    const loadVenue = venueData => {
        setCurrentVenue(venueData[0]);
    };

    const renderLocation = () => {

        const locations = currentVenue.location.split(/[;,]/);
        const originalLocations = locations.map(location => <Location key={uuid()} location={location} moved={isRelocated} context={currentVenue.context}/>);

        let relocations = null;

        if (isRelocated) {
            relocations = currentVenue.relocations.map(relocation => <Location key={uuid()} location={relocation.newLocation} moved={!isRelocated} context={currentVenue.context}/>);
        }
        return(
                <React.Fragment>
                    {originalLocations}
                    {relocations}
                </React.Fragment>
                );
    };

    const extraInfo = (venue) => {

        const renderException = (active, reason, type) => {
            if (active && reason) {
                return (
                        <div className="extra-info-exception">
                            <RemarkItem type={type} />
                            <ExtraInfoItem type={type} reason={reason}/>
                        </div>
                        );
            } else {
                return '';
            }
        };

        const renderInfo = (venue) => {
            if (venue.offer || venue.website || venue.planId) {
                return (
                        <div className="extra-info-info">
                            {venue.offer && <ExtraInfoItem type="offer" offer={venue.offer}/>}
                            {venue.website && <ExtraInfoItem type="website" website={venue.website}/>}
                            {venue.planId && <ExtraInfoItem  type="plan" planId={venue.planId}/>} 
                        </div>
                        );
            } else {
                return '';
            }
        };

        /* for render FN! */
        /*<h2><FontAwesomeIcon icon={faInfo} className="1x"/> Info</h2>*/

        if (!currentVenue.offer && !currentVenue.website && !currentVenue.plandId && !cancellationReason && !relocationReason) {
            if (currentVenue.location) {
                return (
                        <div className="extra-info">
                            <DayTime venue={currentVenue} />
                            {currentVenue.location && renderLocation()}
                        </div>
                        );
            } else {
                return '';
            }

        } else {
            return (
                    <div className="extra-info">
                        <DayTime venue={currentVenue} />
                        {renderException(isCancelled, cancellationReason, "cancellation")}
                        {renderException(isRelocated, relocationReason, "relocation")}
                        {currentVenue.location && renderLocation()}
                        {renderInfo(currentVenue)}
                    </div>
                    );
        }
    };

    if (currentVenue) {
        return (
                <div className={`venue-detail ${currentVenue.venueType === 'MARKET' ? 'market' : 'fair'}`}>
                        <Helmet>
                        <title>Fori: {currentVenue.venueType === 'MARKET' ? t('menuChoices.markets'): t('menuChoices.fairs')}</title>
                    </Helmet>
                    <div className="venue-detail-title">
                        <FontAwesomeIcon icon={faArrowLeft} onClick={handleBackClick} className="4x back-icon"/>
                        <div className="venue-detail-title-text">
                            <span className="venue-detail-market-context">{currentVenue.context.name}</span>
                            <span className="venue-detail-market-name">{currentVenue.name}</span>
                
                        </div>
                    </div>
                    {extraInfo(currentVenue)}
                    {(stalls.length > 0 || searchValue) &&
                                    <div className="filter-container">
                                        <SearchBox onchangefn={setSearchValue} inputName="search" defaultValue="" minLength={0} buffer={0}/>
                                        {categories.length > 0 &&
                                                            <div className="categories-container">
                                                                <h3>{t('categories')}:</h3>
                                                                <select value={selectedCategorie} placeholder="categorie" onChange={(e) => {
                                                                                setWindowScrollPosition(window.pageYOffset);
                                                                                setSelectedCategorie(e.target.value);
                                                                                    }}>
                                                                    <option value='all'>- {t('all')} -</option>
                                                                    {categories && categories.map(cat => <option key={cat} value={cat}>{cat.charAt(0).toUpperCase() + cat.slice(1)}</option>)}
                                                                </select>
                                                            </div>}
                                    </div>
                    }
                    {stalls.length > 0 && <StallList stalls={stalls}/>}
                
                </div>
                );
    } else {

        if (isLoading) {
            return <div className="venue-detail">Loading ...</div>;
        } else {
            return <div className="venue-detail">Not found</div>;
        }
    }


};

export default VenueDetail