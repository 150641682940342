import React, {useState, useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import UrlContext from "../urlContext";

import './ZipCodes.scss';

const MunicipalityItem = (props) => {

    const {zipcode, name} = props.data;

    const addToZipcodesHandler = (e) => {
        props.setzipcodes(zipcodes => {
            if (zipcodes) {
                const exists = zipcodes.find(selectedZipcode => selectedZipcode.postcode === zipcode);
                if (exists) {
                    return zipcodes;
                } else {
                    return [...zipcodes, props.data];
                }
            } else {
                return [props.data];
            }

        });
    };

    const showAddButton = () => {
        const existButton = <FontAwesomeIcon icon={faPlusCircle} onClick={addToZipcodesHandler} className="2x add-icon"/>;
        if (props.selectedzipcodes) {
            const exists = props.selectedzipcodes.find(zipObject => zipObject.zipcode === zipcode);

            if (!exists) {
                return existButton;
            }
        } else {
            return existButton;
        }
    };

    return (
            <div style={{fontSize: '1.5rem', marginBottom: '0.5rem'}}>
                {zipcode} {name} 
                {showAddButton()}            </div>
            );
};

const MunicipalityList = (props) => {
    const items = props.data.map(item => <MunicipalityItem key={item.id} data={item} setzipcodes={props.setzipcodes} selectedzipcodes={props.selectedzipcodes} />);
    return (
            <div style={{margin: "0 0 1rem 0"}}>
                {items}
            </div>
            );
};

const SelectedZipCodeItem = (props) => {

    const removeZipCode = () => {
        //props.setzipcodes()
        props.setzipcodes((prev) => {
            return prev.filter(zipcodeObj => {
                return zipcodeObj.zipcode !== props.zipcode;
            });
        });
    };

    return <div className="zipcode-item">{props.zipcode} {props.city} <FontAwesomeIcon icon={faTimesCircle} onClick={removeZipCode} className="2x remove-icon"/></div>;
};

const ZipCodes = (props) => {
    const {t} = useTranslation();
    const [timedOut, setTimedOut] = useState(null);
    const [municipalities, setMunicipalities] = useState([]);
    const [online, setIsOnline] = useState(true);
    
    const checkConnection = () => {
        if (window.navigator.onLine) {
            setIsOnline(true);
        } else {
            setIsOnline(false);
        }
    };

    const {baseUrl} = useContext(UrlContext);

    const municipalitiesRequest = (value) => {
        checkConnection();
        if (online) {
            if (value.length >= 2) {
                //Fetch zipcodes of w/e met value 
                var url = new URL(`${baseUrl}/municipalities`);
                const options = {
                    method: 'GET',
                    headers: new Headers({'Accept': 'application/json'})
                };
                var paramsString = new URLSearchParams();
                paramsString.append('name', value);
                fetch(url + '?' + paramsString, options)
                        .then(response => response.json())
                        .then(data => {
                            setMunicipalities(() => data.data);
                        });
            } else {
                setMunicipalities([]);
            }
        } else {
            //offline
            //SHOW ERROR

            setMunicipalities([]);
        }
    };

    const zipcodeHandler = (e) => {
        const value = e.target.value;
        if (timedOut) {
            clearTimeout(timedOut);
        }

        setTimedOut(() => {
            return setTimeout(function () {
                municipalitiesRequest(value);
            }, 1000);
        });

    };

    let codes = <div>{t('zipcodesNoneSelected')}</div>;

    if (props.selectedzipcodes !== null && typeof props.selectedzipcodes !== 'undefined' && props.selectedzipcodes.length > 0) {

        codes = props.selectedzipcodes.map(codeObject => {
            return <SelectedZipCodeItem key={codeObject.id} setzipcodes={props.setzipcodes} city={codeObject.name} zipcode={codeObject.zipcode} />;
        });
    }

    let myStyle = {};

    if (!props.noPadding) {
        myStyle = {padding: "0 1rem 1rem 1rem"};
    }

    let content = (
            <div style={myStyle}>
                <div style={{margin: "0 0 1rem 0"}}>
                    <h1 className="title" style={{margin: "0 0 1rem 0"}}>{t('zipcodes')}</h1>
                    {codes}
                </div>
                <h1 className="title" style={{margin: "0 0 1rem 0"}}>{t('zipcodesAdd')}</h1>
                <p className="zipcodes-text"  dangerouslySetInnerHTML={{__html: t('zipcodesText')}}></p>
                <input type="text" placeholder={t('searchTerm')} className="zipcodes-input" onChange={zipcodeHandler} />
                <MunicipalityList data={municipalities} setzipcodes={props.setzipcodes} selectedzipcodes={props.selectedzipcodes}/>
            
            </div>
            );

    if (!online) {
        content = (
                <div className="offline-msg">
                    {t('offlineText')}<br/>
                    {t('offlineText2')}<br/>
                    <button onClick={checkConnection}>{t('offlineCheck')}</button>
                </div>);
    }



    return (
            content
            );
};


export default ZipCodes