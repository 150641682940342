import React from "react";
import { useTranslation } from 'react-i18next';

const ExtraInfoItem = (props) => {
    const {t} = useTranslation();
    let content = '';
    let title = '';
    switch (props.type) {
        case "website":
            title = t('website');
            content = <a href={props.website} rel="noreferrer" target="_blank">{t('websiteLink')}</a>;
            break;
        case "offer":
            title = t('offer');
            content = props.offer;
            break;
        case "plan":
            title = t('marketplan');
            content = <a href={`https://merke.be/files/download/${props.planId}`} rel="noreferrer" target="_blank">{t('marketplanList')}</a>;
            break;
        case "relocation":
        case "cancellation":
            title = props.type === 'cancellation' ? t('cancellationReason') : t('relocationReason');
            content = props.reason;
            break;
        default:
            break;
    }
    return (
            <div className="extra-info-item">
                <span className="title">{title}:</span> {content}
            </div>
            );
};

export default ExtraInfoItem;